import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { CnFirstMammoForm } from '../CnMammo';
import { useGetBiradsLocs } from '../../../../../../platform/hooks/useGetBiradsLocs';
import { useGetStudy } from '../../../../../../platform/app/src/api/studies';
import { Select } from '@mantine/core';

const CnMammoBirads = ({ disabled }: { disabled: boolean }) => {
  const formContext = useFormContext<CnFirstMammoForm>();
  const study = useGetStudy();
  const mg_quality = formContext.watch('mg_quality');

  const { options } = useGetBiradsLocs();

  const biradFields = [
    {
      inputName: 'birads_code_right',
      label: 'Результат R:',
      options: options.birads,
      side: 'right',
    },
    {
      inputName: 'birads_code_left',
      label: 'Результат L:',
      options: options.birads,
      side: 'left',
    },
  ] as const;

  return (
    <>
      <div className="mt-9 grid grid-cols-1 gap-4 sm:grid-cols-2">
        {biradFields.map(({ inputName, label, options, side }) => {
          return (
            <div key={inputName}>
              <div className="flex flex-col">
                <Controller
                  name={inputName}
                  control={formContext.control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        label={label}
                        data={options}
                        error={formContext.formState.errors[inputName]?.message}
                        readOnly={
                          disabled ||
                          (!mg_quality &&
                            study.data?.conclusion.find(c => c.type === 'FIRST_READING')
                              ?.mg_quality?.[side] !== 'GOOD') ||
                          (mg_quality && mg_quality?.[side] !== 'GOOD')
                        }
                      />
                    );
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>

      <Controller
        name="check_value"
        control={formContext.control}
        render={({ field }) => {
          return (
            <Select
              {...field}
              clearable
              label="Описание"
              className="mt-2"
              data={Object.entries(checkValues).map(([value, label]) => ({ value, label }))}
              error={formContext.formState.errors[field.name]?.message}
              readOnly={disabled}
            />
          );
        }}
      />
    </>
  );
};

export default CnMammoBirads;

const checkValues = {
  104: 'Асимметрия',
  105: 'Наличие микрокальцинатов',
  106: 'Наличие образования',
  107: 'Нарушение архитектоники',
};
