export function getMgFramePos(instanceTags: any): 'R-CC' | 'R-MLO' | 'L-CC' | 'L-MLO' | null {
  const viewCode = instanceTags.ViewCodeSequence?.[0]?.CodeValue;
  const laterality =
    instanceTags.ImageLaterality ||
    instanceTags.Laterality ||
    instanceTags.SharedFunctionalGroupsSequence?.[0]?.FrameAnatomySequence?.[0]?.FrameLaterality;

  const typeToCodeMap = {
    CC: 'R-10242',
    MLO: 'R-10226',
  };

  if (laterality === 'R') {
    if (viewCode === typeToCodeMap['CC']) return 'R-CC';
    if (viewCode === typeToCodeMap['MLO']) return 'R-MLO';
  }

  if (laterality === 'L') {
    if (viewCode === typeToCodeMap['CC']) return 'L-CC';
    if (viewCode === typeToCodeMap['MLO']) return 'L-MLO';
  }

  return null;
}
