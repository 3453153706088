import React from 'react';
import { CnFirstMammoForm, CnMammoForm } from '../../CnMammo';
import type { UseFormReturn } from 'react-hook-form';
import { useGetStudy } from '../../../../../../../platform/app/src/api/studies';
import { useGetBiradsLocs } from '../../../../../../../platform/hooks/useGetBiradsLocs';
import { trpc } from '../../../../../../../platform/app/trpc';
import { formatDate } from '../../../../../../../platform/utils/dateUtils';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../../../medreview/server/src';
import { DefaultForm } from '../../CnDefault';
import { Viewer } from '../Viewer';

export function PreviewMammo({
  previewMetadata,
}: {
  previewMetadata: {
    first: UseFormReturn<CnFirstMammoForm>;
    second: UseFormReturn<CnMammoForm>;
  };
}) {
  const firstReader = previewMetadata.first.getValues();
  const secondReader = previewMetadata.second.getValues();
  const study = useGetStudy();
  const firstReaderCn = study.data?.conclusion.find(c => c.type === 'FIRST_READING');
  const secondReaderCn = study.data?.conclusion.find(c => c.type === 'SECOND_READING');
  const user = trpc.general.getUser.useQuery();
  const { options } = useGetBiradsLocs();

  return (
    <div className="bg-[#d4d4d7] py-6">
      <div className="mx-auto w-[816px] bg-white p-[60px]">
        <div className="conclusion-pdf">
          <TopHeaderTemplate study={study.data} />
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Заключение по маммографии</h4>

          <p>
            <span style={{ fontWeight: 'bold' }}>ИИН пациента: </span>
            {study.data?.patients?.iin}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>ФИО пациента: </span>
            {study.data?.patients?.fullname}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Дата рождения пациента: </span>
            {formatDate(study.data?.patients?.bdate)}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Дата прохождения МГ: </span>
            {formatDate(study.data?.date, 'DD.MM.YYYY', 'YYYYMMDD')}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Дата поступления в СКДО: </span>
            {formatDate(
              secondReaderCn?.created_at ||
                (user.data?.roleHelper.isSecondReader ? new Date() : null)
            )}
          </p>
          {firstReader.entranceDoseInmGy && (
            <p>
              <span style={{ fontWeight: 'bold' }}>Эффективная эквивалентная доза: </span>
              {firstReader.entranceDoseInmGy} (мЗв)
            </p>
          )}

          {(
            [
              {
                id: 1,
                title: 'Первая читка маммограмм в поликлинике:',
                form: firstReader,
                // TODO2: show user.data?.fullname first?
                doctor_fullname:
                  firstReaderCn?.doctor_fullname ||
                  (user.data?.roleHelper.isFirstReader ? user.data?.fullname : ''),
                mammo_pic:
                  firstReader.mammo_pic ||
                  (firstReaderCn?.mammo_pic
                    ? `${process.env.S3_URL}/${firstReaderCn?.mammo_pic}`
                    : null),
              },
              {
                id: 2,
                title: 'Вторая читка маммограмм в ОЦ:',
                form: secondReader,
                doctor_fullname:
                  secondReaderCn?.doctor_fullname ||
                  (user.data?.roleHelper.isSecondReader ? user.data?.fullname : ''),
                mammo_pic:
                  secondReader.mammo_pic ||
                  (secondReaderCn?.mammo_pic
                    ? `${process.env.S3_URL}/${secondReaderCn?.mammo_pic}`
                    : null),
              },
            ] as const
          ).map(({ id, title, form, doctor_fullname, mammo_pic }) => (
            <div
              style={{ marginTop: '25px' }}
              key={id}
            >
              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{title}</p>
              <div style={mammo_pic ? { display: 'flex', gap: '10px' } : {}}>
                <div>
                  <table
                    style={
                      mammo_pic ? { width: '546px', marginTop: '10px' } : { marginTop: '10px' }
                    }
                  >
                    <tr>
                      <th style={{ width: '20%' }}></th>
                      <th style={{ width: '40%' }}>R</th>
                      <th style={{ width: '40%' }}>L</th>
                    </tr>
                    <tr>
                      <th style={{ width: '20%' }}>BI-RADS</th>
                      <td style={{ width: '40%' }}>
                        {options.birads.find(b => b.value === form?.birads_code_right)?.label || ''}
                      </td>
                      <td style={{ width: '40%' }}>
                        {options.birads.find(b => b.value === form?.birads_code_left)?.label || ''}
                      </td>
                    </tr>
                    {id !== 2 && (
                      <tr style={{ width: '20%' }}>
                        <th>Локализация</th>
                        <td style={{ width: '40%' }}>
                          {options.localizations.find(b => b.value === form?.localization_right)
                            ?.label || ''}
                        </td>
                        <td style={{ width: '40%' }}>
                          {options.localizations.find(b => b.value === form?.localization_left)
                            ?.label || ''}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>

                {mammo_pic && (
                  <div style={{ width: '150px' }}>
                    <img
                      style={{ width: '100%', height: 'auto' }}
                      src={mammo_pic}
                      crossOrigin="anonymous"
                      alt="mammo"
                    />
                  </div>
                )}
              </div>

              <CnTextTemplate text={form.conclusion_text} />
              <p style={{ marginTop: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Врач-рентгенолог: </span>
                {doctor_fullname || ''}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function PreviewDefault({
  formData,
  study,
}: {
  formData: DefaultForm;
  study: NonNullable<inferRouterOutputs<AppRouter>['study']['get']>;
}) {
  const user = trpc.general.getUser.useQuery();
  const modalityNames = study.modality_study.map(ms => ms.modalities.name);

  const researchType = modalityNames.includes('CT')
    ? 'компьютерной томографии'
    : modalityNames.includes('MR')
    ? 'магнитно-резонансной томографии'
    : modalityNames.includes('DX')
    ? 'рентгена'
    : modalityNames.includes('US')
    ? 'УЗИ'
    : '';

  const maxScale = formData.attachments.length > 1 ? 338 : 512;

  return (
    <div className="bg-[#d4d4d7] py-6">
      <div className="mx-auto w-[816px] bg-white p-[60px]">
        <div className="conclusion-pdf">
          <TopHeaderTemplate study={study} />
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Отделение лучевой диагностики</h4>
          {researchType && (
            <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Кабинет {researchType}</h4>
          )}
          <p>
            <span style={{ fontWeight: 'bold' }}>ИИН пациента: </span>
            {study?.patients?.iin}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>ФИО пациента: </span>
            {study?.patients?.fullname}
          </p>
          <p>
            <span style={{ fontWeight: 'bold' }}>Дата исследования: </span>
            {formatDate(study?.date, 'DD.MM.YYYY', 'YYYYMMDD')}
          </p>
          <CnTextTemplate text={formData.conclusion_text} />
          <DoctorInfoTemplate user={user.data} />
          <p>
            NB! Данное заключение не является диагнозом и должно быть интерпретировано лечащим
            врачом в соответствии с клиническими симптомами и лабораторными данными.
          </p>

          {formData.attachments.length > 0 && (
            <div>
              <div
                style={{
                  marginTop: '90px',
                  display: 'grid',
                  justifyContent: 'center',
                  gap: '20px',
                  gridTemplateColumns: formData.attachments.length > 1 ? '1fr 1fr' : '1fr',
                }}
              >
                {formData.attachments.map(a => (
                  <div style={{ margin: '0 auto' }}>
                    <div style={{ backgroundColor: '#000', color: '#fff', padding: '3px' }}>
                      <p style={{ fontSize: '11px', lineHeight: 1 }}>{formatDate(study.date)}</p>
                      <p style={{ fontSize: '11px', lineHeight: 1 }}>{study.patients?.fullname}</p>
                    </div>

                    {a.type === 'CORNERSTONE' && (
                      <Viewer
                        prefix="attachment-preview-"
                        imgUrl={a.url}
                        properties={a.metadata.properties}
                        size={{ height: `${maxScale}px`, width: `${maxScale}px` }}
                      />
                    )}

                    {a.type !== 'CORNERSTONE' && (
                      <img
                        style={
                          formData.attachments.length === 1
                            ? { width: '512px', height: '512px' }
                            : { width: 'auto', height: '338px' }
                        }
                        src={`${process.env.S3_URL}/${a.url}`}
                        alt="Не удалось загрузить"
                        crossOrigin="anonymous"
                      />
                    )}
                  </div>
                ))}
              </div>
              <DoctorInfoTemplate user={user.data} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function TopHeaderTemplate({ study }: { study?: inferRouterOutputs<AppRouter>['study']['get'] }) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '90px',
        }}
      >
        <div>
          {study?.organizations?.logo_url && (
            <img
              style={{ maxHeight: '100px', display: 'block' }}
              src={`https://10910-medreview.object.pscloud.io/${study.organizations.logo_url}`}
              alt="organization logo"
              crossOrigin="anonymous"
            />
          )}
        </div>
        <div style={{ textAlign: 'right', fontSize: '12px' }}>
          {study?.organizations?.requisites && (
            <div dangerouslySetInnerHTML={{ __html: study.organizations.requisites }}></div>
          )}
        </div>
      </div>
      <div
        style={{
          borderBottom: '1px solid #d4ebe8',
          margin: 'auto',
          textAlign: 'center',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      ></div>
    </>
  );
}

function CnTextTemplate({ text }: { text: string }) {
  return (
    <div
      style={{ marginTop: '10px' }}
      className="document-editor document-editor-preview"
    >
      <div
        className="ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred"
        dangerouslySetInnerHTML={{ __html: text }}
      ></div>
    </div>
  );
}

function DoctorInfoTemplate({
  user,
}: {
  user?: inferRouterOutputs<AppRouter>['general']['getUser'];
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        breakInside: 'avoid',
        marginTop: '15px',
        minHeight: '136px',
      }}
    >
      <p>Врач:</p>
      {user?.stamps_url ? (
        <img
          style={{ maxHeight: '160px', margin: '5px', display: 'block' }}
          src={`${process.env.S3_URL}/${user.stamps_url}`}
          alt="stamp"
          crossOrigin="anonymous"
        />
      ) : (
        <span>_________________</span>
      )}
      <p>{user?.fullname || ''}</p>
    </div>
  );
}
