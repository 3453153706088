import React, { useMemo, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Loader,
  MultiSelect,
  Select,
  Table,
  Text,
} from '@mantine/core';
import dayjs from 'dayjs';
import { DatePickerInput } from '@mantine/dates';
import { formatDate } from '../../../../utils/dateUtils';
import { Decimal } from 'decimal.js';
import { Header } from '@ohif/ui';
import UserService from '../../userService';
import { useNavigate } from 'react-router';
import { saveAs } from 'file-saver';
import { IconArrowUpRight } from '@tabler/icons-react';
import { trpc } from '../../../trpc';
import { inferRouterOutputs, inferRouterInputs } from '@trpc/server';
import type { AppRouter } from '../../../../../../medreview/server/src';
import { notifications } from '@mantine/notifications';

export default function StudiesReport() {
  const [paymentType, setPaymentType] = useState<
    inferRouterInputs<AppRouter>['report']['getStudiesReport']['paymentType'] | undefined
  >();
  const [dateFrom, setDateFrom] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(dayjs().format('YYYY-MM-DD'));
  const [modalities, setModalities] = useState<string[]>([]);
  const [doctorsIin, setDoctorsIin] = useState<string[]>([]);
  const navigate = useNavigate();

  const filters = useMemo(() => {
    return {
      paymentType,
      dateFrom,
      dateTo,
      modalities,
      doctorsIin,
    };
  }, [paymentType, dateFrom, dateTo, modalities, doctorsIin]);

  const studiesReport = trpc.report.getStudiesReport.useQuery(filters);

  const getStudiesReportExcel = trpc.report.getStudiesReportExcel.useMutation({
    onSuccess: res => {
      saveAs(
        `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.base64}`,
        `${
          paymentType === 'military_office'
            ? 'Реестр призывников, прибывших из военкомата за'
            : 'Список завершенных исследований за'
        } ${formatDate(dateFrom)} - ${formatDate(dateTo)}.xlsx`
      );
    },
    onError: () => {
      notifications.show({
        title: 'Ошибка',
        message: 'Ошибка при загрузке файла',
        color: 'red',
        autoClose: 5000,
      });
    },
  });

  const user = trpc.general.getUser.useQuery();
  const userOrg = user.data?.organization_user.map(ou => ou.organization_id!);
  const availableModalities = trpc.general.getModalities.useQuery({
    orgIds: userOrg,
  });
  const responsibleUsers = trpc.general.getUsers.useQuery({
    orgIds: userOrg,
    canMakeConclusions: true,
  });

  return (
    <Box>
      <Header
        menuOptions={[
          {
            icon: 'power-off',
            title: 'Выйти',
            onClick: () => {
              UserService.doLogout();
            },
          },
        ]}
        isReturnEnabled={true}
        onClickReturnButton={() => navigate('/')}
      >
        <p></p>
      </Header>

      <Box>
        <Flex className="mt-5 gap-4 px-12 pt-3 pb-3">
          <DatePickerInput
            clearable={false}
            miw={126}
            label="От:"
            valueFormat="DD.MM.YYYY"
            maxDate={new Date(dateTo)}
            value={new Date(dateFrom)}
            onChange={date => setDateFrom(dayjs(date).format('YYYY-MM-DD'))}
          />
          <DatePickerInput
            clearable={false}
            miw={126}
            label="До:"
            valueFormat="DD.MM.YYYY"
            minDate={new Date(dateFrom)}
            maxDate={new Date()}
            value={new Date(dateTo)}
            onChange={date => setDateTo(dayjs(date).format('YYYY-MM-DD'))}
          />

          <Select
            label="Оплата"
            data={[
              {
                value: 'free',
                label: 'Бесплатно',
              },
              {
                value: 'military_office',
                label: 'Военкомат',
              },
              {
                value: 'paid',
                label: 'Платно',
              },
            ]}
            onChange={v => setPaymentType(v as typeof paymentType)}
          />

          <MultiSelect
            label="Тип"
            data={
              availableModalities.data?.map(m => ({
                value: m.name!,
                label: m.name!,
              })) || []
            }
            onChange={setModalities}
          />

          <MultiSelect
            label="Врач-исполнитель"
            data={
              responsibleUsers.data?.map(u => ({
                value: u.username,
                label: u.fullname || '',
              })) || []
            }
            onChange={setDoctorsIin}
          />

          <Button
            className="self-end"
            loading={getStudiesReportExcel.isLoading}
            onClick={() => getStudiesReportExcel.mutate(filters)}
          >
            Скачать в Excel
          </Button>
        </Flex>

        {studiesReport.isLoading && (
          <Loader
            color="blue"
            className="mx-auto mt-6"
          />
        )}

        {!studiesReport.isLoading && <StudiesReportTable studies={studiesReport.data || []} />}
      </Box>
    </Box>
  );
}

function StudiesReportTable({
  studies,
}: {
  studies: inferRouterOutputs<AppRouter>['report']['getStudiesReport'];
}) {
  const user = trpc.general.getUser.useQuery();

  return (
    <Table
      withBorder
      withColumnBorders
      className="mt-2"
    >
      <thead>
        <tr>
          <th>ID</th>
          <th>Дата исследования</th>
          <th>Тип исследования</th>
          <th>ИИН</th>
          <th>ФИО</th>
          <th>Дата рождения</th>
          {user.data?.roleHelper.isAssistant && <th>Направляющие организации</th>}
          <th>Врачи-исполнители</th>
          <th>Лаборант</th>

          {user.data?.roleHelper.isFirstReader && (
            <>
              <th>Результаты 1 чтения L</th>
              <th>Результаты 1 чтения R</th>
              <th>Рекомендации</th>
            </>
          )}
          {user.data?.roleHelper.isSecondReader && (
            <>
              <th>Результаты 2 чтения L</th>
              <th>Результаты 2 чтения R</th>
              <th>Рекомендации</th>
            </>
          )}
          <th>Услуги</th>
          <th>Тип оплаты</th>

          {user.data?.roleHelper.isAssistant && (
            <>
              <th>Общее количество оказанных услуг</th>
              <th>Сумма</th>
            </>
          )}

          <th>Результаты</th>
        </tr>
      </thead>
      <tbody>
        {studies.map(s => {
          const preCnRes = s?.conclusion.find(c => c.type === 'FIRST_READING');
          const cnRes = s?.conclusion.find(c => c.type === 'SECOND_READING');

          const sum = s.services
            .filter(s => s.price.price)
            .reduce(
              (total, service) =>
                new Decimal(service.price.price!)!.mul(service.service_count)?.add(total),
              new Decimal(0)
            );

          const services = s.services.map(s => {
            const name = s.price.services?.short_service_name || s.price.services?.service_name;
            const count = s.service_count;
            const price = Intl.NumberFormat('ru').format(s.price.price);

            return `${name} - ${count} ед. (Стоимость - ${price || ''} тг.)`;
          });

          const doctors = s?.conclusion.map(s => s.doctor_fullname || '');
          const modalities = s.modality_study.map(m => m.modalities.name);
          const sentOrg = s?.payment_type
            ? s?.payment_type.payment_type === 'OSMS'
              ? s?.appointment.map(a => <Text key={a.senderMoName}>{a.senderMoName}</Text>)
              : 'Платно'
            : ' ';

          // TODO2: multi conclusion. responsible user?
          const conclusionUrl = s?.conclusion[0]?.conclusion_url;

          return (
            <tr key={s.id}>
              <td>{s.id}</td>
              <td>{formatDate(s.date, 'DD.MM.YYYY', 'YYYYMMDD')}</td>
              <td>{modalities.join(', ')}</td>
              <td>{s.patients?.iin}</td>
              <td>{s.patients?.fullname}</td>
              <td>{formatDate(s.patients?.bdate)}</td>
              {user.data?.roleHelper.isAssistant && <td>{sentOrg}</td>}
              <td>
                <Flex className="flex-col gap-4">
                  {doctors.map(d => (
                    <Text key={d}>{d}</Text>
                  ))}
                </Flex>
              </td>
              <td>{s.physician_user?.fullname}</td>

              {user.data?.roleHelper.isFirstReader && (
                <>
                  <td>
                    {preCnRes?.birads_left?.category} {preCnRes?.birads_left?.description}
                  </td>
                  <td>
                    {preCnRes?.birads_right?.category} {preCnRes?.birads_right?.description}
                  </td>
                  <td>
                    <Flex className="flex-col gap-4">
                      {preCnRes?.recs?.split(' ||| ').map(r => <Text key={r}>{r}</Text>)}
                    </Flex>
                  </td>
                </>
              )}

              {user.data?.roleHelper.isSecondReader && (
                <>
                  <td>
                    {cnRes?.birads_left?.category} {cnRes?.birads_left?.description}
                  </td>
                  <td>
                    {cnRes?.birads_right?.category} {cnRes?.birads_right?.description}
                  </td>
                  <td>
                    <Flex className="flex-col gap-4">
                      {cnRes?.recs?.split(' ||| ').map(r => <Text key={r}>{r}</Text>)}
                    </Flex>
                  </td>
                </>
              )}

              <td>
                <Flex className="flex-col gap-4">
                  {services.map(s => (
                    <Text key={s}>{s}</Text>
                  ))}
                </Flex>
              </td>
              <td>{s.payment_type?.payment_type_rus}</td>

              {user.data?.roleHelper.isAssistant && (
                <>
                  <td>{s.services.reduce((acc, s) => acc + s.service_count, 0)}</td>
                  <td>{`${Intl.NumberFormat('ru').format(sum.toNumber())} тг.`}</td>
                </>
              )}

              <td>
                <Flex className="items-center justify-center">
                  {conclusionUrl && (
                    <ActionIcon
                      variant="filled"
                      onClick={() =>
                        window.open(`${process.env.S3_URL}/${conclusionUrl}`, '_blank')
                      }
                    >
                      <IconArrowUpRight stroke={2} />
                    </ActionIcon>
                  )}
                </Flex>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
